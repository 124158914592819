<template>
  
  <!-- Admin dropdown menu -->
  <div v-if="currentRole=== 'System Manager'" class="hs-dropdown relative inline-flex">
    <button id="hs-dropdown-custom-trigger" type="button" class="hs-dropdown-toggle py-1 ps-1 pe-3 inline-flex 
      items-center gap-x-0.5 text-sm font-semibold rounded-md border border-gray-200 bg-customNavbar 
      text-gray-800 shadow-sm hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none 
      dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none 
      dark:focus:ring-1 dark:focus:ring-gray-600">
      <!-- <img class="w-8 h-auto rounded-full" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" alt="Maria"> -->
      <div class="size-6 md:size-8 rounded flex justify-center items-center text-xl border-1.5 border-black bg-[#fef3c2]">
        <!-- P -->
         {{ userDetails.first_name[0] }}
      </div>
      <!-- <span class="text-gray-600 font-medium truncate max-w-[7.5rem] dark:text-gray-400">Maria</span> -->
      <svg class="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
    </button>

    <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 
      opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border 
      dark:border-gray-700 z-50" aria-labelledby="hs-dropdown-custom-trigger">
      <router-link v-if="userDetails && userDetails.is_system_manager" class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
      to="/front-portal/user-management">
        User management
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager && userDetails.first_name === 'Sandeep'" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/admin-controls">
        Admin Controls
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/observations/summary">
        Summary Generation
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/observations/observation-link">
        Link Observations
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/observations/summary-individual">
        Individual Observations
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/calendar/plan-week">
        Plan Week(Test)
      </router-link>
      <router-link v-if="userDetails && userDetails.is_system_manager"
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/sandbox">
        Prompt Sandbox
      </router-link>
      <a v-if="userDetails && userDetails.is_system_manager && (userDetails.first_name.toLowerCase() === 'sai' 
        || userDetails.first_name.toLowerCase() === 'sandeep' || userDetails.first_name.toLowerCase() === 'priyanka' )" 
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" href="https://testpod.comini.in/student-reports/">
        Student Reports
      </a>
      <router-link v-if="userDetails && userDetails.is_system_manager"
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700" to="/front-portal/admin/messaging">
        Admin Messaging
      </router-link>
      <!-- <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
        Purchases
      </a>
      <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
        Downloads
      </a> -->
        <!-- class="py-1 px-3 text-sm md:py-1.5 md:px-1 md:text-base ml-2 md:ml-1 hover:bg-customNavbar border-black" > -->
      <div v-if="userDetails && userDetails.user_roles.includes('Parent') && userDetails.user_roles.length > 1" class="flex items-center gap-x-3.5 py-2 px-3 rounded-sm text-sm hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 text-black cursor-pointer bg-[#FFF8D7]"  :class="{ 'border mx-1.5': !userDetails.user_roles.includes('System Manager') }"
        @click="toggleRole">
        View as {{ displayRole }}
      </div>

      <div class="md:hidden flex items-center gap-x-3.5 py-2 px-3 rounded-sm text-sm hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 text-black cursor-pointer"
        @click="showInstallZippieModal">
        Install Zippie
      </div>

      <button 
        v-if="auth.isLoggedIn" 
        @click="auth.logout()"
        class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
        focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
        dark:focus:bg-gray-700 w-full">
        logout
      </button>

      <!-- Show version modal -->
      <div class="flex items-center gap-x-3.5 py-2 px-3 font-medium text-gray-400 rounded-sm text-xs hover:bg-customNavbar 
          focus:outline-none focus:bg-gray-100 cursor-pointer"
          @click="showVersionModel">

          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6965_63847)">
            <path d="M7.10091 13.485C10.3226 13.485 12.9342 10.8734 12.9342 7.65169C12.9342 4.43003 10.3226 1.81836 7.10091 1.81836C3.87925 1.81836 1.26758 4.43003 1.26758 7.65169C1.26758 10.8734 3.87925 13.485 7.10091 13.485Z" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.10107 9.98568V7.65234" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.10107 5.31836H7.10691" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_6965_63847">
            <rect width="14" height="14" fill="white" transform="translate(0.101074 0.652344)"/>
            </clipPath>
            </defs>
          </svg>

          <span>{{ VERSION_INFO.formattedVersion }}</span> 

          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7677 2.65234H3.43441C2.69803 2.65234 2.10107 3.2493 2.10107 3.98568V13.319C2.10107 14.0554 2.69803 14.6523 3.43441 14.6523H12.7677C13.5041 14.6523 14.1011 14.0554 14.1011 13.319V3.98568C14.1011 3.2493 13.5041 2.65234 12.7677 2.65234Z" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.43457 5.98633H10.7679V11.3197" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.43457 11.3197L10.7679 5.98633" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

      </div>

      

    </div>
  </div>

  <!-- Parent Dropdown -->
  <div v-else class="hs-dropdown relative inline-flex">

    <!-- Menu button -->
    <button id="hs-dropdown-custom-trigger" type="button" class="hs-dropdown-toggle py-1 ps-1 pe-3 inline-flex 
        items-center gap-x-0.5 text-sm font-semibold rounded-md border border-gray-200 bg-customNavbar 
        text-gray-800 shadow-sm hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none 
        dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none 
        dark:focus:ring-1 dark:focus:ring-gray-600">
        <!-- <img class="w-8 h-auto rounded-full" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" alt="Maria"> -->
        <div class="size-6 md:size-8 rounded flex justify-center items-center text-xl border-1.5 border-black bg-[#fef3c2]">
          <!-- P -->
          {{ userDetails.first_name[0] }}
        </div>
        <!-- <span class="text-gray-600 font-medium truncate max-w-[7.5rem] dark:text-gray-400">Maria</span> -->
        <svg class="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
    </button>

    <!-- dropdown body -->
    <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 
        opacity-0 hidden bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border 
        dark:border-gray-700 z-50" aria-labelledby="hs-dropdown-custom-trigger">
      
        <!-- Switch roles -->
        <div v-if="userDetails && userDetails.user_roles.includes('Parent') && userDetails.user_roles.length > 1" class="flex items-center gap-x-3.5 py-2 px-3 rounded-sm text-sm hover:bg-customNavbar 
          focus:outline-none focus:bg-gray-100 text-black cursor-pointer bg-[#FFF8D7] flex justify-center"
          @click="toggleRole">
          View as {{ displayRole }}
        </div>

        <!-- Install zippie -->
        <div class="md:hidden flex gap-x-3.5 py-2 px-3 rounded-sm text-sm hover:bg-customNavbar 
          focus:outline-none focus:bg-gray-100 text-black cursor-pointer bg-[#FFF8D7] flex justify-center mt-2 mb-2"
          @click="showInstallZippieModal">
          <div>Install Zippie</div>  
        </div>

        <!-- Logout -->
        <button 
          v-if="auth.isLoggedIn" 
          @click="auth.logout()"
          class="flex justify-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-customNavbar 
          focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 
          dark:focus:bg-gray-700 w-full">
          Logout
        </button>


        <!-- Show version modal button -->
        <div class="flex justify-center items-center gap-x-3.5 py-2 px-3 font-medium text-gray-400 rounded-sm text-xs hover:bg-customNavbar 
          focus:outline-none focus:bg-gray-100 cursor-pointer"
          @click="showVersionModel">

          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6965_63847)">
            <path d="M7.10091 13.485C10.3226 13.485 12.9342 10.8734 12.9342 7.65169C12.9342 4.43003 10.3226 1.81836 7.10091 1.81836C3.87925 1.81836 1.26758 4.43003 1.26758 7.65169C1.26758 10.8734 3.87925 13.485 7.10091 13.485Z" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.10107 9.98568V7.65234" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.10107 5.31836H7.10691" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_6965_63847">
            <rect width="14" height="14" fill="white" transform="translate(0.101074 0.652344)"/>
            </clipPath>
            </defs>
          </svg>

          <span>{{ VERSION_INFO.formattedVersion }}</span> 

          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7677 2.65234H3.43441C2.69803 2.65234 2.10107 3.2493 2.10107 3.98568V13.319C2.10107 14.0554 2.69803 14.6523 3.43441 14.6523H12.7677C13.5041 14.6523 14.1011 14.0554 14.1011 13.319V3.98568C14.1011 3.2493 13.5041 2.65234 12.7677 2.65234Z" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.43457 5.98633H10.7679V11.3197" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.43457 11.3197L10.7679 5.98633" stroke="#A8A69E" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </div>

    </div>  
  </div>  
</template>

<script setup>
import { inject, watch, defineEmits, onMounted, computed  } from 'vue';
import roleSwitchConfirmationModal from './navigationMenu/roleSwitchConfirmationModal.vue';
import { VERSION_INFO } from '../../versionDetailsConfig';

const auth = inject('$auth')
const userDetails = inject('userDetails')
const emit = defineEmits(['roleChanged','show-install-zippie-modal']);


console.log("profile dropdown",userDetails.value)

watch(userDetails, newDetails => {
  console.log(newDetails)
})


const props = defineProps({
  currentRole: {
      type: String,
      required: true,
    },
  });

// to display right role in the switch role button text
const nextRole = computed(() => {
  if (props.currentRole === 'Parent') {
    // Prioritized order of roles to switch back to
    const priorityRoles = ['Guest Facilitator','System Manager', 'Educator',];
    
    // Find the highest priority role that the user has
    for (const role of priorityRoles) {
      
      if (userDetails.value.user_roles.includes(role)) {
        return role;
      }
    }
    // If no other role found, default to 'Guest Facilitator'
    return 'Guest Facilitator';
  } else {
    // If current role is not 'Parent', switch to 'Parent'
    return 'Parent';
  }
});


const toggleRole = () => {
  emit('roleChanged');
};

const showVersionModel = () =>{
  emit('showVersionModel')
}

const showInstallZippieModal = () => {
  emit('show-install-zippie-modal');
};

// Added this to Render 'Educator' for 'Guest Facilitator' without changing existing funtions 
const displayRole = computed(() => {
  return nextRole.value === 'Guest Facilitator' ? 'Educator' : nextRole.value;
});

  
</script>