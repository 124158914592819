// versionDetailsConfig.js
export const VERSION_INFO = Object.freeze({
    number: '1.0.13',
    date: '2nd Dec',
    fullVersion: 'Zippie.1.0.13',
    formattedVersion: 'V.1.0.13 · 2nd Dec',
    changes: [
      'Added Weekly summaries for parents',
      'Added ability to upload images for each individual observation ',
      'Added version number and changes list in the dropdown menu',
      'Added error banner on top of the app',
      'Photo essays timeline view image rendering order updated',
      // { text: '', highlight: true }
    ]
  })




